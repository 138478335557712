<template>
	<div class="bg-mandarin">
		<div class="anime-hover-white mode-cennter py-16 text-center text-pine segment_text" v-if="data_get_in_touch.content" v-html="data_get_in_touch.content">
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	export default {
		name: "GetInTouch",
		data() {
			return {
				data_get_in_touch: globals.segments['get-in-touch'],
			};
		},
	};
</script>

<style></style>
