<template>
	<div class="reletive bg-shea pt-140 pb-100">
		<div class="mode-center pb-100">
			<div class="w-7/12 mx640:w-full mx640:mb-10">
				<h1 class="h1-text mx900:text-6">
					{{ txt_we_have_worked_with }}
				</h1>
			</div>
			<div class="flex justify-end">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="partner_arrow mr-4 cursor-pointer"
					width="35"
					height="35"
					viewBox="0 0 35 35"
					@click="prevSlide"
				>
					<g fill="" fill-rule="evenodd">
						<g>
							<g transform="translate(-734 -2905) translate(734 2905)">
								<circle cx="17.5" cy="17.5" r="17.5" fill="" />
								<g>
									<g>
										<g>
											<path
												d="M6 10L12 16 18 10"
												transform="translate(5.5 5.5) rotate(180 12 12) rotate(-90 12 12)"
												class="partner_stroke"
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<svg
					@click="nextSlide"
					class="partner_arrow cursor-pointer"
					xmlns="http://www.w3.org/2000/svg"
					width="35"
					height="35"
					viewBox="0 0 35 35"
				>
					<g fill="" fill-rule="evenodd">
						<g>
							<g transform="translate(-779 -2905) translate(779 2905)">
								<circle cx="17.5" cy="17.5" r="17.5" fill="" />
								<g>
									<g>
										<path
											class="partner_stroke"
											d="M6 10L12 16 18 10"
											transform="translate(5.5 5.5) rotate(-90 12 12)"
										/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</div>
		</div>
		<div class="mode-center">
			<swiper class="swiper carousel" :options="swiperOption">
				<div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
					<a :href="slide.link" target="_blank">
						<div
							class="bg-white relative w-48 h-48 rounded-full mx420:w-250 mx420:h-250"
						>
							<img
								class="centery px-10"
								v-if="slide.image"
								:src="slide.image.sm"
								alt=""
							/>
						</div>
					</a>
				</div>
			</swiper>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";
	import { Swiper } from "vue-awesome-swiper";
	import "swiper/css/swiper.css";
	export default {
		name: "PartnerCarousel",
		components: {
			Swiper,
		},
		data() {
			return {
				swiperOption: {
					spaceBetween: 30,
					effect: "slide",
					grabCursor: true,
					speed: 400,
					breakpoints: {
						320: {
							slidesPerView: 1,
						},
						421: {
							slidesPerView: 2,
							centeredSlides: true,
							centeredSlidesBounds: true,
						},
						640: {
							slidesPerView: 3,
						},
						900: {
							slidesPerView: 4,
							centeredSlides: false,
							centeredSlidesBounds: false,
						},
						1080: {
							slidesPerView: 5,
						},
						1280: {
							slidesPerView: 6,
						},
					},
				},
				txt_we_have_worked_with:
					globals.microcopy.txt_we_have_worked_with[globals.language],
			};
		},
		props: ["slides"],
		methods: {
			nextSlide() {
				const swiper = document.querySelector(".carousel").swiper;
				swiper.slideNext();
			},
			prevSlide() {
				const swiper = document.querySelector(".carousel").swiper;
				swiper.slidePrev();
			},
		},
	};
</script>

<style scoped>
	.partner_arrow {
		fill: #193e3a;
		transition: fill 0.4s ease;
	}
	.partner_arrow:hover {
		fill: #d6444a;
	}
	.partner_stroke {
		stroke: #fff;
	}
</style>
